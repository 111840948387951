import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { ModelSelect } from 'vue-search-select';
import gstrNineDtls from './gstrNineDtls/';
import { required } from 'vuelidate/lib/validators';
import outwardInwardTaxPayable from './outwardInwardTaxPayable';
import availedItcDtls from './availedItcDtls';
import revIneligibleItcDtls from './revIneligibleItcDtls';
import hsnOutwardInward from './hsnOutwardInward/';
export default {
  components: {
    ModelSelect,
    gstrNineDtls,
    outwardInwardTaxPayable,
    availedItcDtls,
    revIneligibleItcDtls,
    hsnOutwardInward
  },
  data() {
    return {
      loader: false,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      totalRows: null,
      defaultValue: {
        value: null,
        text: null
      },
      legalEntity: {
        value: null,
        text: null
      },
      financialYear: {
        value: null,
        text: null
      },
      financialYearList: [],
      gstNumber: {
        value: null,
        text: null
      },
      period: {
        value: null,
        text: null
      },
      showValueSetModal: false,
      showGstr9DtlsModal: false,
      gstrNineData: [],
      filedFlag: false,
      tab: null,
      tabDetail: null,
      childName: null,
      searchValue: {
        le_id: null,
        le_name: null,
        gst_number: null,
        period: null,
        financialYear: null,
        filed_flag: null
      },
      editMode: false,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      tabType: null
    };
  },
  validations: {
    legalEntity: {
      text: { required }
    },
    gstNumber: {
      text: { required }
    },
    financialYear: {
      text: { required }
    },
    period: {
      text: { required }
    }
  },
  mounted() {
    this.getFinancialYearList();
  },
  methods: {
    getGstrNineData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          le_id: this.legalEntity.value,
          fin_year: this.financialYear.value,
          gst_number: this.gstNumber.value,
          period: this.period.value
        };
        this.loader = true;
        this.$store
          .dispatch('gstrNine/getGstrNineData', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              const result = response.data.data;
              this.gstrNineData = result;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    checkIndex(index, item) {
      switch (index) {
        case 0 || 1:
          this.showHideGstr9DtlsModal(true, 'outwardInwardTaxPayable', item);
          break;
        case 2:
          this.showHideGstr9DtlsModal(true, 'availedItcDtls', item);
          break;
        case 3 || 4 || 5 || 6 || 7 || 8 || 9:
          this.showHideGstr9DtlsModal(true, 'revIneligibleItcDtls', item);
          break;
        case 10 || 11:
          this.showHideGstr9DtlsModal(true, 'hsnOutwardInward', item);
          break;
        case 11:
          this.showHideGstr9DtlsModal(true, 'hsnOutwardInward', item);
          break;
        default:
          this.showHideGstr9DtlsModal(true, 'revIneligibleItcDtls', item);
      }
    },
    setChildName(tab, item) {
      if (
        tab === 'outwardInwardTaxPayable' ||
        tab === 'availedItcDtls' ||
        tab === 'revIneligibleItcDtls' ||
        tab === 'hsnOutwardInward'
      ) {
        return (this.childName = item.heading);
      }
    },
    showHideGstr9DtlsModal(flag, component, item) {
      this.showGstr9DtlsModal = flag;
      this.tab = component;
      this.tabDetail = item;
      this.tabType = item ? item.type : null;
      this.searchValue.le_id = this.legalEntity.value;
      this.searchValue.gst_number = this.gstNumber.value;
      this.searchValue.period = this.period.value;
      this.searchValue.filed_flag = this.filedFlag;
      this.setChildName(this.tab, item);
    },
    toggle() {
      this.filedFlag != this.filedFlag;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.parent_value_set_id = null;
      }

      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.org_id,
          text: item.org_name
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.gstNumber = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        this.period = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clear() {
      this.legalEntity = this.defaultValue;
      this.gstNumber = this.defaultValue;
      this.period = this.defaultValue;
      this.financialYear = this.defaultValue;
      this.parent_value_set_id = null;
      this.gstrNineData = [];
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = this.defaultValue;
      } else if (vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN) {
        this.gstNumber = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        this.period = this.defaultValue;
      }
    },
    getFinancialYearList() {
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/getFinancialYearList')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const finYearList = results.map(type => {
              return {
                value: type.fy_display,
                text: type.fy_display
              };
            });
            this.financialYearList = finYearList;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
