export default {
  props: ['tabDetail', 'searchValue'],
  components: {},
  data() {
    return {
      availedItcData: [
        {
          sub_heading: null,
          sub_type_name: null,
          taxable_value: null,
          integrated_tax: null,
          state_tax: null,
          central_tax: null,
          cess: null
        }
      ],
      availedItcFields: [
        {
          key: 'sub_heading',
          label: 'sub_heading',
          class: 'text-center col-fix-subHeading'
        },
        {
          key: 'sub_type_name',
          label: 'Description',
          class: 'text-center col-fix-subType'
        },
        {
          key: 'taxable_value',
          label: 'Taxable Value (₹)',
          class: 'text-center'
        },
        {
          key: 'central_tax',
          label: 'Central Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'state_tax',
          label: 'State/UT Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'integrated_tax',
          label: 'Integrated Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'cess',
          label: 'CESS (₹)',
          class: 'text-center'
        }
      ],
      tab10Fields: [
        {
          key: 'sub_heading',
          label: 'sub_heading',
          class: 'text-center col-fix-subHeading'
        },
        {
          key: 'sub_type_name',
          label: 'Description',
          class: 'text-center col-fix-tab9'
        },
        {
          key: 'Taxpayable',
          label: 'Tax Payable (₹)',
          class: 'text-center'
        },
        {
          key: 'Paid_through_cash',
          label: 'Paid through Cash (₹)',
          class: 'text-center'
        },
        {
          key: 'Tax_Paid_Itc_Camt',
          label: 'Central Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'Tax_Paid_Itc_Samt',
          label: 'State/UT Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'Tax_Paid_Itc_Iamt',
          label: 'Integrated Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'Tax_Paid_Itc_Csamt',
          label: 'CESS (₹)',
          class: 'text-center'
        }
      ],
      tab14Fields: [
        {
          key: 'sub_heading',
          label: 'sub_heading',
          class: 'text-center '
        },
        {
          key: 'sub_type_name',
          label: 'Description',
          class: 'text-center '
        },
        {
          key: 'Taxpayable',
          label: 'Payable (₹)',
          class: 'text-center'
        },
        {
          key: 'Tax_paid',
          label: 'Paid (₹)',
          class: 'text-center'
        }
      ],
      tab15Fields: [
        {
          key: 'sub_heading',
          label: 'sub_heading',
          class: 'text-center col-fix-subHeading'
        },
        {
          key: 'sub_type_name',
          label: 'Details',
          class: 'text-center col-fix-tab9'
        },
        {
          key: 'central_tax',
          label: 'Central Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'state_tax',
          label: 'State/UT Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'integrated_tax',
          label: 'Integrated Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'cess',
          label: 'CESS (₹)',
          class: 'text-center'
        },
        {
          key: 'interest',
          label: 'Interest (₹)',
          class: 'text-center'
        },
        {
          key: 'penalty',
          label: 'Penalty (₹)',
          class: 'text-center'
        },
        {
          key: 'late_fee',
          label: 'Late Fee (₹)',
          class: 'text-center'
        }
      ],
      tab16Fields: [
        {
          key: 'sub_heading',
          label: 'sub_heading',
          class: 'text-center col-fix-subHeading'
        },
        {
          key: 'sub_type_name',
          label: 'Details',
          class: 'text-center col-fix-tab9'
        },
        {
          key: 'taxable_value',
          label: 'Taxable Value (₹)',
          class: 'text-center'
        },
        {
          key: 'central_tax',
          label: 'Central Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'state_tax',
          label: 'State/UT Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'integrated_tax',
          label: 'Integrated Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'cess',
          label: 'CESS (₹)',
          class: 'text-center'
        }
      ],
      tab17Fields: [
        {
          key: 'sub_heading',
          label: 'sub_heading',
          class: 'text-center col-fix-subHeading'
        },
        {
          key: 'sub_type_name',
          label: 'Details',
          class: 'text-center col-fix-tab9'
        },
        {
          key: 'taxable_value',
          label: 'Taxable Value (₹)',
          class: 'text-center'
        },
        {
          key: 'central_tax',
          label: 'Central Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'state_tax',
          label: 'State/UT Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'integrated_tax',
          label: 'Integrated Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'cess',
          label: 'CESS (₹)',
          class: 'text-center'
        }
      ],
      unsubscribe: null,
      editMode: false,
      initialTaxOutwardData: [],
      tabType: null
    };
  },
  mounted() {
    if (this.tabDetail) {
      this.tabType = this.tabDetail.type;
      this.availedItcFields =
        this.tabType === 'TABLE_9'
          ? this.tab10Fields
          : this.tabType === 'TABLE_14'
          ? this.tab14Fields
          : this.tabType === 'TABLE_15'
          ? this.tab15Fields
          : this.tabType === 'TABLE_16'
          ? this.tab16Fields
          : this.tabType === 'TABLE_17'
          ? this.tab17Fields
          : this.availedItcFields;
    }
    this.getGstr9TabsData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.editGstrNineTabData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getGstr9TabsData() {
      const payload = {
        le_id: this.searchValue.le_id,
        gst_number: this.searchValue.gst_number,
        period: this.searchValue.period,
        type: this.tabDetail.type
      };
      this.loader = true;
      this.$store
        .dispatch('gstrNine/getGstrNineTabsData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.availedItcData = response.data.data.detail;
            // this.initialTaxOutwardData = JSON.parse(
            //   JSON.stringify(response.data.data.detail)
            // );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
