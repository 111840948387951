export default {
  props: ['tabDetail', 'searchValue'],
  components: {},
  data() {
    return {
      availedItcData: [
        {
          sub_heading: null,
          sub_type_name: null,
          taxable_value: null,
          integrated_tax: null,
          state_tax: null,
          central_tax: null,
          cess: null
        }
      ],
      availedItcFields: [
        {
          key: 'sub_heading',
          label: '6',
          class: 'text-center col-fix-subHeading'
        },
        {
          key: 'sub_type_name',
          label: 'Description',
          class: 'text-center col-fix-subType'
        },
        {
          key: 'sub_type',
          label: 'Type',
          class: 'text-center'
        },
        {
          key: 'taxable_value',
          label: 'Taxable Value (₹)',
          class: 'text-center'
        },
        {
          key: 'central_tax',
          label: 'Central Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'state_tax',
          label: 'State/UT Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'integrated_tax',
          label: 'Integrated Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'cess',
          label: 'CESS (₹)',
          class: 'text-center'
        }
      ],
      unsubscribe: null,
      editMode: false,
      initialTaxOutwardData: [],
      tabType: null
    };
  },
  mounted() {
    if (this.tabDetail) {
      this.tabType = this.tabDetail.type;
    }
    this.getGstr9TabsData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.editGstrThreeBTabData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getGstr9TabsData() {
      const payload = {
        le_id: this.searchValue.le_id,
        gst_number: this.searchValue.gst_number,
        period: this.searchValue.period,
        type: this.tabDetail.type
      };
      this.loader = true;
      this.$store
        .dispatch('gstrNine/getGstrNineTabsData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.availedItcData = response.data.data.detail;
            // this.initialTaxOutwardData = JSON.parse(
            //   JSON.stringify(response.data.data.detail)
            // );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
    // selectAllBoxChecked(flag) {
    //   this.selectedRow = [];
    //   if (flag) {
    //     this.taxOutwardData = this.taxOutwardData.map((data, index) => {
    //       data.selectBox = this.selectAllCheckBox;
    //       if (this.selectAllCheckBox) {
    //         this.selectBoxChecked(data.selectBox, index);
    //       }
    //       return data;
    //     });
    //     this.selectedRow = this.taxOutwardData;
    //   } else {
    //     this.taxOutwardData = this.taxOutwardData.map((data, index) => {
    //       data.selectBox = this.selectAllCheckBox;
    //       if (this.selectAllCheckBox) {
    //         this.selectBoxChecked(data.selectBox, index);
    //       }
    //       return data;
    //     });
    //   }
    // },
    // selectBoxChecked(flag, index, item, subTypeName) {
    //   this.taxOutwardData[index].selectBox = flag;
    //   if (flag) {
    //     this.selectedRow.push(item);
    //   } else {
    //     this.selectedRow = this.selectedRow.filter(
    //       el => el.request_id !== subTypeName
    //     );
    //   }
    // },
    // editGstrThreeBTabData() {
    //   const data = [];
    //   for (let i = 0; i < this.taxOutwardData.length; i++) {
    //     const itemA = this.initialTaxOutwardData[i];
    //     const itemB = this.taxOutwardData[i];
    //     if (
    //       itemA.taxable_value != itemB.taxable_value ||
    //       itemA.central_tax != itemB.central_tax ||
    //       itemA.state_tax != itemB.state_tax ||
    //       itemA.cess != itemB.cess ||
    //       itemA.integrated_tax != itemB.integrated_tax
    //     ) {
    //       data.push({
    //         le_id: this.searchValue.le_id,
    //         gst_no: this.searchValue.gst_number,
    //         period_name: this.searchValue.period,
    //         correct_taxable_value: itemB.taxable_value.replace(/,/g, ''),
    //         correct_igst_amount: itemB.integrated_tax.replace(/,/g, ''),
    //         correct_cgst_amount: itemB.central_tax.replace(/,/g, ''),
    //         correct_sgst_amount: itemB.state_tax.replace(/,/g, ''),
    //         correct_cess_amount: itemB.cess.replace(/,/g, ''),
    //         sub_type: this.taxOutwardData[i].sub_type,
    //         sub_sub_type: itemB.sub_sub_type ? itemB.sub_sub_type : '',
    //         type: this.tabDetail.type
    //       });
    //     }
    //   }
    //   const payload = {
    //     page: data
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('gstrThreeBSummary/addEditGstrThreeBTabsData', payload)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         this.editMode = false;
    //         this.$bvToast.toast(resp.data.message, {
    //           title: 'Alert',
    //           variant: 'success',
    //           solid: true
    //         });
    //         this.$emit('getGstrThreeBSummaryTabData');
    //         this.getGstrThreeBTabsData();
    //       } else {
    //         this.$bvToast.toast(resp.response.data.message, {
    //           title: 'Alert',
    //           variant: 'danger',
    //           solid: true
    //         });
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
